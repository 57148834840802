@import '../../style/variables';

.requestBetaToolBar {
  height: $header-height;
  background: #000000;
  display: flex;
  justify-content: space-between;
  padding: 0 $mobile-padding-sides;

  @media (min-width: $extraSmallScreenWidth) {
    padding: 0 48px;
  }
}

.actionButtons {
  margin: 0;
  padding: 0;
  display: flex;
}
