@import '../../../style/variables';
@import '../../../style/mixin';

.info-icon {
  @include interactionCommonStyle();
  background: transparent;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 300;
  color: $default-text-color;
}
