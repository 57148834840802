@import '../../style/variables';
@import '../../style/mixin';
@import '../../style/variables';

.editorXToolbar {
  font-family: $editorX-font-family, "HelveticaNeueW01-45Ligh", Helvetica, Arial, sans-serif;
  height: 72px;
  background: #000000;
  display: flex;
  justify-content: space-between;
  padding: 0 38px;
}

.logo {
  width: 114px;
}

.actionButtons {
  margin: 0;
  padding: 0;
  display: flex;
}
