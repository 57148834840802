@import '../../../style/variables';


.mobileEditorXToolbar {
  font-family: $editorX-font-family, "HelveticaNeueW01-45Ligh", Helvetica, Arial, sans-serif;
  height: $header-editorx-height;
  background: #000000;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  @media (min-width: $extraSmallScreenWidth) {
    padding: 0 48px;
  }
}

.logo {
  width: 85px;
}

.allTemplatesButton {
  font-size: 16px;
  height: 100%;
  max-height: 38px;
  align-self: center;
  text-decoration: none;
  white-space: nowrap;
  align-items: center;

  display: flex;
  color: #000000;
  background: #FFFFFF;
  margin-right: 0;
  border-radius: 31px;
  padding: 0 20px;
  text-align: center;


  &:hover {
    opacity: 0.9;
  }
}
