.logo {
  display: inline-block;
  background-image: url('./EditorXLogo.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.hide {
  opacity: 0;
}
