@import '../../style/variables';
@import '../../style/mixin';

$card-margin-top: calc(#{$header-height} + 1vh);
$content-default-size: 504px;
$content-tablet-size: 80%;
$card-default-padding: 72px 72px 0;
$card-tablet-padding: 10%x 10% 0;
$card-mobile-padding: 34px 21px 0;

.popup-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(white, .8);
  display: none;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: auto;

  &.show {
    display: flex;
  }

  @include screen-size(mobile) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.button {
  border: none;
  cursor: pointer;

  &.editor {
    @include button();
    font-size: $default-edit-button-font-size;
  }
}

.close-button {
  background: url('./close.svg') no-repeat center center;
  background-size: 19px;
  border: 0;
  cursor: pointer;
  height: 40px;
  overflow: hidden;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 40px;

  &:hover {
    background-image: url('./close-hover.svg');
  }
}

.card {
  @include shadow();
  background-color: white;
  margin-top: $card-margin-top;
  position: relative;


  @include screen-size(tablet-width) {
    width: $content-tablet-size;
  }

  @include screen-size(mobile) {
    flex-grow: 1;
    margin-top: $mobile-header-height;
  }
}

.content {
  padding: $card-default-padding;
  width: $content-default-size;

  @include screen-size(tablet-width) {
    padding: $card-tablet-padding;
    max-width: $content-tablet-size;
  }

  @include screen-size(mobile) {
    padding: $card-mobile-padding;
    width: auto;
  }
}

.title {
  @include font(100, .7px);
  font-size: 32px;
  line-height: $heading-line-height;
  margin: 0 0 round($margin-paragraph * 1.4);

  @include screen-size(tablet-width) {
    margin: 0 0 round($margin-paragraph * 1.2);
  }

  @include screen-size(mobile) {
    margin: 0 0 round($margin-paragraph * 1.2);
  }
}

.subtitle {
  @include font(500);
  font-size: $default-font-size;
  margin-bottom: $margin-heading;

  @include screen-size(medium) {
    font-size: $medium-font-size;
  }
}

.running-text {
  @include font(300);

  @include screen-size(tablet-width) {
    font-size: $tablet-font-size;
  }
}

.feature {
  margin-bottom: $margin-paragraph;

  @include screen-size(tablet-width) {
    margin: 0 0 round($margin-paragraph * .75);
  }
}

.price {
  margin-bottom: $margin-heading;
  @include screen-size(tablet-width) {
    margin: 0 0 round($margin-paragraph * .75);
  }
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: $margin-paragraph + 8px;
  padding: 0 0 72px;
  width: 100%;

  @include screen-size(mobile) {
    border-top: 1px solid #ddd;
    margin: 0 $mobile-padding-sides;
    padding: $margin-paragraph 0 34px;
    width: auto;
  }
}

.desktop-only-notice {
  @include font(500);
}

.hide {
  opacity: 0;
}
