@import '../../style/variables';
@import '../../style/mixin';

$tool-bar-icons-map: $mobile-icon, $desktop-icon;

@include generate-icons($tool-bar-icons-map);

.price,
.title {
  @include font(300);
}

.title {
  margin: $header-element-default-spacing;
  @include screen-size(medium) {
    & {
      margin: $header-element-medium-spacing;
    }
  }
}

.tool-bar {
  @include toolBarSettings();
}

.elements-group {
  @include base-flex();
}

.button {
  @include button();
  margin: $header-element-xlarge-spacing;

  @include screen-size(medium) {
    & {
      margin: $header-element-large-spacing;
    }
  }
}

.hr {
  margin: 0 25px;
  height: 25px;
  border-left: none;
  border-bottom: none;
  border-top: none;
  border-color: $icon-disabled-color;
  opacity: .5;

  .action-menu & {
    margin: $header-element-default-spacing;
  }

  @include screen-size(medium) {
    & {
      margin: $header-element-medium-spacing;
    }
  }
}

.hide {
  opacity: 0;
}
