@import './variables';
@import './functions';

@mixin font-family($family) {
  font-family: $family, Helvetica, Arial, sans-serif;
}

@mixin font($weight, $spacing: .4px) {
  font-weight: $weight;
  letter-spacing: $spacing;

  @if $weight == 100 {
    @include font-family('HelveticaNeueW01-Thin');
  } @else if $weight == 300 {
    @include font-family('HelveticaNeue45W01-Ligh');
  } @else if $weight == 400 {
    @include font-family('HelveticaNeueW01-45Ligh');
  } @else if $weight == 500 {
    @include font-family('HelveticaNeueW01-65Medi');
  }
}

@mixin button() {
  $height: 42px;
  $horizontalPadding: 27px;
  $backgroundColor: #4196e0;
  $hoverBackgroundColor: #459fed;

  border: none;
  cursor: pointer;
  display: inline-block;
  @include font(100);
  padding: 0 $horizontalPadding;
  height: $height;
  line-height: $height;
  border-radius: $height / 2;
  text-align: center;
  color: white;
  background: $backgroundColor;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    background: $hoverBackgroundColor;
    transition: all linear 0.2s;
  }

  &.selected {
    background: $backgroundColor;
  }

  @include screen-size(medium) {
    & {
      font-size: $medium-font-size;
    }
  }

  @include screen-size(mobile) {
    $height: 22px;
    $horizontalPadding: 16px;

    padding: 0 $horizontalPadding;
    font-size: $mobile-font-size;
    height: $height;
    line-height: $height;
    border-radius: $height / 2;
  }
}

@mixin base-flex() {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
}

@mixin interactionCommonStyle() {
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    color: $color;
  }
}

@mixin generate-single-icon($icon) {
  .#{map-get($icon, 'name')}-icon {
    $image: #{map-get($icon, 'image')};
    $hover-image: #{map-get($icon, 'hover-image')};
    width: #{map-deep-get($icon, 'default', 'width')};
    height: #{map-deep-get($icon, 'default', 'height')};
    background: $image no-repeat;

    @include interactionCommonStyle();

    &.selected,
    &:hover {
      background: $hover-image no-repeat;
    }

    @include screen-size(medium) {
      width: #{map-deep-get($icon, 'medium', 'width')};
      height: #{map-deep-get($icon, 'medium', 'height')};
    }

    @include screen-size(mobile) {
      width: #{map-deep-get($icon, 'small', 'width')};
      height: #{map-deep-get($icon, 'small', 'height')};
    }
  }
}

@mixin generate-icons($icons-map) {

  @each $icon in $icons-map {
    @include generate-single-icon($icon);
  }
}

@mixin shadow() {
  box-shadow: 0 2px 18px 0 rgba(129, 162, 182, .2);
}

@mixin fake-phone-part() {
  content: '';
  display: inline-block;
  width: 100%;
  height: 62px;
  background: white;
  @include phone-shadow();
}

@mixin phone-shadow() {
  box-shadow: -15px 8px 20px -10px rgba(0, 0, 0, .2);
}

@mixin screen-size($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $map: map-get($breakpoints, $breakpoint);
    $media: clean-media-query-map($map);

    @media #{$media} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin toolBarSettings() {
  @include base-flex();
  @include shadow();
  height: $header-height;
  padding: 0 48px;
  justify-content: space-between;
  z-index: 10;
  position: relative;
  background: white;

  @include screen-size(medium) {
    padding: 0 1vw;
    width: 100vw;
  }

  @include screen-size(mobile) {
    height: $mobile-header-height;
    left: 0;
    padding: 0 $mobile-padding-sides;
    position: fixed;
    top: 0;
    width: 100%;
  }
}
