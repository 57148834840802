@import '../../style/variables';
@import '../../style/mixin';

.demo-display {
  width: 100%;
  height: calc(100vh - #{$header-height});
}

.disabled {
  pointer-events: none;
}
