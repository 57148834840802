@import '../../../style/mixin';
@import '../../../style/variables';

.logo-icon {
  width: 66px;
  height: 24px;
  background: url('./Wixlogo.svg') no-repeat;
}

.logo {
  margin-right: $logo-default-margin;

  @include screen-size(medium) {
    margin-right: $logo-medium-margin;
  }
}

.hide {
  opacity: 0;
}
