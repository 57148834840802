@import '../../style/variables';
@import '../../style/mixin';

:global {
  body {
    &.popup {
      overflow: hidden;
    }
  }
}
