@import '../../style/variables';
@import '../../style/mixin';

.tool-bar {
  @include toolBarSettings();
}

.iframe {
  bottom: 0;
  display: block;
  left: 0;
  right: 0;
  position: absolute;
  top: $mobile-header-height;

  &.editorXToolbar {
    top: $header-editorx-height;
  }
}

.iframe-hidden {
  display: none;
}

.title {
  font-size: $mobile-font-size;
}
