@import "../style/variables";
@import "../style/mixin";

.appppppp {
  background-color: red;
}

:global {
  html,
  body {
    color: $default-text-color;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    @include font(400);
    font-size: $default-font-size;
    line-height: $default-line-height;
    background-color: $body-background-color;

    @include screen-size(medium) {
      font-size: $medium-font-size;
    }

    @include screen-size(mobile) {
      line-height: $mobile-line-height;
    }
  }

  button,
  label,
  h1 {
    font-size: inherit;
    margin: 0;
  }

  h3 {
    margin: 0 0 $margin-heading;
  }

  button {
    overflow: hidden;
  }

  iframe {
    border: none;
  }

  p {
    margin: 0 0 $margin-paragraph;
  }
}

@font-face {
  font-family: $editorX-font-family;
  src: url("../../assets/fonts/WixMadeforDisplay_W_Rg.woff") format("woff");
}
