@import '../../../style/variables';
@import '../../../style/mixin';

.display {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  border: none;
  overflow: hidden;
}


.iframe{
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}
