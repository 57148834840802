$icon-disabled-color: #20303c;
$color: #3aa6ff;
$hover-color: rgba(#3aa6ff, .9);
$phone-background-color: #c2c7cb;
$speaker-color: #eff1f2;
$body-background-color: #f9fafa;

$medium-scale-element-by: .3;

$editorX-font-family: "WixMadeforDisplay_W_Rg";
$default-font-size: 16px;
$mobile-font-size: 8px;
$medium-font-size: 14px;
$tablet-font-size: 12px;
$default-edit-button-font-size: 16px;

$card-min-height: 441px;
$card-bottom-padding: 36px;

$logo-default-width: 66px;
$logo-medium-width: calc((#{$logo-default-width} - (#{$logo-default-width} * #{$medium-scale-element-by})));
$logo-default-height: 24px;
$logo-medium-height: calc((#{$logo-default-height} - (#{$logo-default-height} * #{$medium-scale-element-by})));
$logo-default-margin: 47px;
$logo-medium-margin: calc(#{$logo-default-margin} * #{$medium-scale-element-by});

$breakpoints: (
  medium: (
    max-width: 1100px,
    max-height: 0
  ),
  tablet-width: (
    max-width: 910px,
    max-height: 0
  ),
  tablet: (
    max-width: 0,
    max-height: 910px
  ),
  mobile: (
    max-width: 320px,
    max-height: 0
  )
);

$desktop-icon: (
  'name': 'desktop',
  'image': url('./desktop.svg'),
  'hover-image': url('./desktop-hover.svg'),
  'default': (
    'width': 31px,
    'height': 26px,
  ),
  'medium': (
    'width': 26px,
    'height': 24px,
  ),
  'small': (
    'width': 26px,
    'height': 24px,
  )
);

$mobile-icon: (
  'name': 'mobile',
  'image': url('./mobile.svg'),
  'hover-image': url('./mobile-hover.svg'),
  'default': (
    'width': 17px,
    'height': 28px,
  ),
  'medium': (
    'width': 17px,
    'height': 28px,
  ),
  'small': (
    'width': 17px,
    'height': 28px,
  )
);

$logo-icon: (
  'name': 'logo',
  'image': url('./Wixlogo.svg'),
  'hover-image': url('./Wixlogo.svg'),
  'default': (
    'width': $logo-default-width,
    'height': $logo-default-height,
  ),
  'medium': (
    'width': $logo-medium-width,
    'height': $logo-medium-height,
  ),
  'small': (
    'width': $logo-medium-width,
    'height': $logo-medium-height,
  )
);

$header-height: 72px;
$header-editorx-height: 55px;
$mobile-header-height: 62px;
$header-element-xlarge-spacing: 0 40px;
$header-element-large-spacing: 0 30px;
$header-element-default-spacing: 0 17px;
$header-element-medium-spacing: 0 14px;
$min-screen-width: 798px;
$margin-paragraph: 30px;
$margin-heading: 8px;
$default-line-height: 1.5;
$heading-line-height: 1.38;
$mobile-line-height: 1.38;
$default-text-color: #20303c;
$mobile-padding-sides: 18px;
$extraSmallScreenWidth: 768px;
