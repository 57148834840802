@import '../../../style/variables';
@import '../../../style/mixin';

$display-width: 320px;

.display {
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.device {
  $max-height: 566px;

  @include phone-shadow();
  width: $display-width;
  height: $max-height;
  max-height: $max-height;
  background-color: $phone-background-color;
}

.speaker {
  width: 24px;
  height: 3px;
  border-radius: 1.5px;
  background-color: $speaker-color;
  position: absolute;
  top: 23px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.phone {
  $cornerRadius: 46px;
  $phone-gap: -3px;

  width: $display-width;
  height: 660px;
  position: relative;


  &:before {
    @include fake-phone-part();
    border-radius: $cornerRadius $cornerRadius 0 0;
    margin-bottom: $phone-gap;
  }
  &:after {
    @include fake-phone-part();
    border-radius: 0 0 $cornerRadius $cornerRadius;
    margin-top: $phone-gap;
  }

  @include screen-size(tablet) {
    & {
      top: $header-height;
      left: 50%;
      transform: translateX(-50%);
      margin: $header-height auto 0;
      position: absolute;
    }
  }
}
