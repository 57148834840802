@import 'src/client/style/variables';
@import 'src/client/style/mixin';
@import 'src/client/style/variables';

.dropdownMenu {
  margin: 0 0 0 -17px;
  padding: 19px 27px 24px 17px;
  position: absolute;
  top: 100%;
  background: #000000;

  display: none;

  .actionItem:hover & {
    display: block;
  }
}

.actionItem {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  position: relative;

  .dropdownMenu & {
    display: block;
  }
}

.actionLink {
  margin-right: 38px;
  color: #FFFFFF;
  font-size: 16px;
  height: 100%;
  max-height: 46px;
  align-self: center;
  text-decoration: none;
  white-space: nowrap;
  align-items: center;

  &:hover {
    opacity: 0.85;
  }

  .dropdownMenu & {
    padding: 6px 0;
  }

  display: none;

  @media (min-width: $extraSmallScreenWidth) {
    display: flex;
  }

  &.isButton {
    display: flex;
    color: #000000;
    background: #FFFFFF;
    margin-right: 0;
    border-radius: 25px;
    padding: 0 27px;
    text-align: center;

    &:hover {
      opacity: 0.9;
    }
  }
}
